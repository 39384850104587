@import "../../mixins.scss";

.about {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  @include lg {
    padding: 0px 120px;
  }
  h2{
    color: #fafafa;
    font: normal 700 45px/120% $font-a, $font-c;
    letter-spacing: -1.44px;
    align-self: stretch;
    margin: 0px;
    @include sm {
      font-size: 60px;
      line-height: 120px;
    }
    @include lg {
      font-size: 72px;
      line-height: 125%;
    }
  }
  p{
    color: white;
    font: normal 400 24px/133% $font-a, $font-c;
    margin: 0px;
    @include lg {
      font-size: 30px;
      line-height: 126%;
    }
  }
  .about-details {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    gap: 100px;
    align-self: stretch;
    @include lg {
      flex-direction: row;
    }
    h3{
      color: #fafafa;
      font: normal 700 24px/133% $font-a, $font-c;
      align-self: stretch;
      margin: 0px;
    }
    .about-details-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      gap: 50px;
      flex: 1 0 0;
      .content {
        display: flex;
        padding: 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        align-self: stretch;
        @include md {
          padding: 0px 20px;
        }
        .c-line {
          display: flex;
          align-items: flex-start;
          gap: 30px;
          align-self: stretch;
          @include md {
            align-items: center;
          }
          .c-l-icon {
            display: flex;
            padding: 10.5px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 20px;
            height: 20px;
            border-radius: 2000px;
            background: #f1f1f1;
            overflow: visible;
            @include md {
              width: 35px;
              height: 35px;
              padding: 17.5px;
            }
          }
          .c-l-text {
            color: white;
            font: 500 16px/24px $font-a, $font-c;
          }
        }
      }
    }
    .about-details-right {
      display: flex;
      width: 100%;
      max-width: 500px;
      margin: 0px auto;
      @include lg {
        margin: 0px;
      }
    }
  }
  
}