@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./mixins.scss";
@import url("https://use.typekit.net/bmh3tux.css");

html {
  scroll-behavior: smooth;
  background-color: $cc-900;
}

html, body, #root {
  width: 100%;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}