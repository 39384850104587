@import "../../mixins.scss";

.footer {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: $cc-25;
  flex-wrap: wrap;
  .footer-link {
    cursor: pointer;
    font: normal 500 10px/normal $font-a, $font-c;
    color: $black;
    text-align: center;
    text-decoration-line: underline;
    @include lg{
      font-size: 12px;
    }
  }
  .footer-legal {
    color: $black;
    text-align: center;
    font: normal 400 10px/normal $font-a, $font-c;
    @include lg{
      font-size: 12px;
    }
  }
}