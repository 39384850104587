@import "../../mixins.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  height: max-content;
  position: relative;
  .bgImageContainer {
    background-image: url("../../assets/images/chordCash-bg.webp");
    background-size: cover;
    height: 564px;
    width: 100%;
    position: absolute;
  }
  .tncContianter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    padding: 20px;
    width: 100%;
    z-index: 1;
    @include nav-bp {
      margin-top: 0px;
      padding: 120px;
    } 
  }
  .subContainer {
    @include f-c;
    padding: 30px 20px;
    width: 100%;
    max-width: 1040px;
    background-color: $white;
    z-index: 1;
    font-family: $font-1;
    border-radius: 6px;
    @include md {
      padding: 60px 120px;
    }
    .lastUpdateAt {
      @include font-acumin-sb;
      font-size: 32px;
      color: $tuna;
      text-transform: uppercase;
      @media screen and (max-width: 767px) {
        font-size: 27px;
      }
    }
    .divider {
      height: 3px !important;
      background-color: $tuna !important;
      margin-bottom: 25px;
      @include md {
        margin-bottom: 35px;
      }
    }

    h1 {
      @include h3;
      padding-bottom: 15px;
    }
    h2 {
      @include font-acumin-sb;
      font-size: 32px;
      line-height: 0.9;
      color: $tuna;
      padding: 5px 0 20px 0;
      @include md {
        font-size: 40px;
      }
    }
    h3 {
      @include font-acumin-sb;
      font-size: 24px;
      color: $tuna;
      line-height: 0.9;
      padding: 5px 0 20px 0;
      @include md {
        font-size: 32px;
      }
    }
    h4 {
      padding-bottom: 35px;
    }
    .linkContainer {
      display: flex;
      flex-direction: column;
      padding-bottom: 15px;
      gap: 1px;
      a {
        color: $tuna;
        text-decoration: underline;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    p, li {
      font-size: 12px;
      color: $tuna;
      line-height: 150%;
      @include md {
        font-size: 14px;
      }
    }
    p {
      padding-bottom: 10px;
    }
    li{
      padding-bottom: 5px;
    }
    ul {
      list-style-type: disc !important;
      padding-inline-start: 15px;
      @include md {
        padding-inline-start: 25px;
      }
    }
    a {
      color: $tuna;
      font-weight: 500;
    }
  }
}
