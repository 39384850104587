@import "../../mixins.scss";

.landing {
  background-image: url("../../assets/images/chordCash-bg.webp");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding-top: 60px;
  @include lg{
    padding-top: 120px;
  }
  
  .landing-container {
    @include lg {
      padding: 50px 120px;
    }
    display: flex;
    padding: 50px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex: 1 1 auto;
    max-width: 1200px;
    h1{
      align-self: stretch;
      color: $white;
      text-align: center;
      font: normal 700 36px/125% $font-a, $font-c;
      letter-spacing: -0.96px;
      margin: 0px;
      @include lg {
        font-size: 48px;
        align-self: auto;
      }
    }
    p{
      max-width: 800px;
      margin: 0px;
      color: $white;
      text-align: center;
      font: normal 400 18px/155% $font-a, $font-c;
    }
    .landing-read-more {
      max-width: 300px;
      box-sizing: border-box;
      display: flex;
      flex: 1 1 auto;
      padding: 14.5px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 2000px;
      background-color: $wild-sand;
      color: $black;
      text-align: center;
      font: normal 700 14px/normal $font-a, $font-c;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        transition: background-color 0.2s linear;
        background-color: $cc-200;
      }
    }
  }
}