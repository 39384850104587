@import "../../mixins.scss";

.cc-text-area{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  p {
    color: $cc-600;
    font: normal 500 14px/142% $font-a, $font-c;
  }
}

.text-area {
  display: flex;
  padding: 0px 0px 5px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  font: normal 700 14px/142% $font-a, $font-c;
  color: $mineshaft;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgb(47,47,48, 0.4);
  &:hover {
    border-bottom-color: rgb(47,47,48, 0.5);
  }
  &:focus, &:active {
    border-bottom-color: rgb(47,47,48);
    outline: none;
  }
  &::placeholder {
    font: normal 700 14px/142% $font-a, $font-c;
    color: rgb(47,47,48, 0.4);
  }
}