@import "../../mixins.scss";

.inputContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    label {
        color: $mineshaft;
        font: normal 500 14px/142% $font-a, $font-c;
        width: 100%;
        margin:0;
        padding:0;
    }
    input {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 5px;
        align-self: stretch;
        font: normal 700 14px/142% $font-a, $font-c;
        color: $mineshaft;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgb(47,47,48, 0.4);
        &:hover {
            border-bottom-color: rgb(47,47,48, 0.5);
        }
        &:focus, &:active {
            border-bottom-color: rgb(47,47,48);
            outline: none;
        }
        &::placeholder {
            font: normal 700 14px/142% $font-a, $font-c;
            color: rgb(47,47,48, 0.4);
        }
    }
}