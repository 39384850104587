$xs: 0px;
$xs-sm: 288px;
$sm: 576px;
$sm-md: 672px;
$md: 768px;
$md-lg: 908px;
$lg: 1048px;
$lg-xl: 1224px;
$xl: 1400px;
$xl-xxl: 1650px;
$xxl: 1900px;
$xxl-xk: 2200px;
$xk: 2500px;
$nav-bp: 990px;

$c-lg: 1024px;
$c-xl: 1440px;
$c-xxl: 1920px;

$xs-n: 0;
$xs-sm-n: 288;
$sm-n: 576;
$sm-md-n: 672;
$md-n: 768;
$md-lg-n: 908;
$lg-n: 1048;
$lg-xl-n: 1224;
$xl-n: 1400;
$xl-xxl-n: 1650;
$xxl-n: 1900;
$xxl-xk-n: 2200;
$xk-n: 2500;

$c-lg-n: 1024;
$c-xl-n: 1440;
$c-xxl-n: 1920;

$ai-st: "stretch";
$ai-fs: "flex-start";
$ai-s: "start";
$ai-fe: "flex-end";
$ai-e: "end";
$ai-c: "center";
$ai-b: "baseline";
$ai-sb: "space-between";

$jc-fs: "flex-start";
$jc-fe: "flex-end";
$jc-c: "center";
$jc-s: "start";
$jc-e: "end";
$jc-l: "left";
$jc-r: "right";
$jc-sb: "space-between";
$jc-sa: "space-around";

$font-a: Poppins;
$font-b: Poppins;
$font-c: sans-serif;
$font-1: $font-a, $font-c;
$font-2: $font-b, $font-c;
$font-acumin: acumin-variable, $font-1;

$black: #000000;
$white: #ffffff;

//Primary Color

$cc-25: #FAFAFA;
$cc-50: #F5F5F5;
$wild-sand: #F1F1F1;
$cc-200: #E2E2E2;
$cc-300: #CACACA;
$cc-400: #A3A3A4;
$cc-500: #7D7D7D;
$cc-600: #565657;
$tuna: #2F2F30;
$mineshaft: #282828;
$cc-900: #141414;

