@import "../../mixins.scss";

.button {
  display: flex;
  min-width: 200px;
  padding: 14.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 2000px;
  border: none;
  box-shadow: 0px 7px 15px 0px rgba(47, 47, 48, 0.25);
  @include lg {
    padding: 14.5px 50px;
  }
  &-enabled {
    cursor: pointer;
    background-color: #2F2F30;
  }
  &-enabled:hover {
    background-color: #282828;
    cursor: pointer;
  }
  &-enabled:active {
    background-color: #141414;
  }
  &-disabled {
    cursor: default;
    background: rgba(47,47,48, 0.25);
  }
  .button-text a, .button-text {
    color: #FFFFFF;
    font: normal 700 16px/150% Poppins, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
  }
}