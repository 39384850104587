@import "../../mixins.scss";

.partners {
  @include xl {
    padding: 0px 120px;
  }
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 25px;
  max-width: 1440px;
  width: 100%;
  .partners-title {
    font: bold 18px/32px $font-a, $font-c;
    color: $cc-25;
    align-self: stretch;
  }
  .divider {
    height: 1px;
    align-self: stretch;
    background-color: $cc-25;
  }
  .partners-list {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 25px 40px;
    align-self: stretch;
    flex-wrap: wrap;
    .partner {
      .partner-logo {
        height: auto;
        margin: auto;
        max-width: 125px;
        max-height: 125px;
      }
    }
  }
}