@import "../../mixins.scss";

.popUp {
    @include f-r;
    position: fixed;
    box-sizing: border-box;
    min-width: 100px;
    top: 25px;
    color: $white;
    background-color: $mineshaft;
    border: 1px solid $white;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0px 15px;
    z-index: 2000;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    @include lg {

    }
    .logo {
      background-size: 25px auto;
      padding: 10px;
      border-right: 1px solid $white;
      background-color: $white;
      svg g path{
        fill: $mineshaft;
      }
    }
    .content {
      @include f-c;
      @include fs-c;
      padding: 10px;
      .title {
        font: 700 24px/28px $font-a, $font-c;
      }
      .text {
        font: 500 18px/28px $font-a, $font-c;
      }
    }
  }