@import "../../../mixins.scss";

#err-icon svg path{
  fill: $mineshaft;
}
#err-icon {
  padding-top: 15px;
}
#err-text{
  display: flex;
  align-self: stretch;
  flex-direction: column;
  gap: 15px;
  .title {
      font: normal 27px/32px $font-a, $font-c;
      font-weight: 700;
      color: $tuna;
      align-self: stretch;
      text-align: center;
  }
  .subtitle {
      font: normal 17px/26px $font-a, $font-c;
      font-weight: 500;
      color: $cc-900;
      align-self: stretch;
      text-align: center;
  }
}