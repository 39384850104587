@forward "./sass/constants.scss";
@use "sass:list";

$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$ai-st: "stretch";
$ai-fs: "flex-start";
$ai-s: "start";
$ai-fe: "flex-end";
$ai-e: "end";
$ai-c: "center";
$ai-b: "baseline";
$ai-sb: "space-between";

$jc-fs: "flex-start";
$jc-fe: "flex-end";
$jc-c: "center";
$jc-s: "start";
$jc-e: "end";
$jc-l: "left";
$jc-r: "right";
$jc-sb: "space-between";
$jc-sa: "space-around";

@mixin bp {
  @include xl {@content;}
}
@mixin nav-bp {
  @media (min-width: $nav-bp) {@content;}
}
@mixin f-c-bp {
  @include f-c-xl;
}
@mixin f-c-bp-($gap1, $gap2: -1) {
  @include f-c-xl-($gap1,$gap2);
}
@mixin f-r-bp {
  @include f-r-xl;
}
@mixin f-r-bp-($gap1, $gap2: -1) {
  @include f-r-xl-($gap1,$gap2);
}

@mixin h {height: 100%;}
@mixin w {width: 100%;}

@mixin h-w {
  @include h;
  @include w;
}

@mixin h-w-($hw) {
  height: $hw;
  width: $hw;
}

@mixin f {display: flex;}
@mixin i-f {display: inline-flex;}

@mixin r-g-($gap) {row-gap: $gap;}
@mixin c-g-($gap) {column-gap: $gap;}

@mixin f-r {
  @include f;
  flex-direction: row;
}

@mixin f-r-($gap) {
  @include f-r;
  @include c-g-($gap);
}

@mixin f-c {
  @include f;
  flex-direction: column;
}

@mixin f-c-($gap) {
  @include f-c;
  @include r-g-($gap);
}

@mixin i-f-r {
  @include i-f;
  flex-direction: row;
}

@mixin i-f-r-($gap) {
  @include i-f-r;
  @include c-g-($gap);
}

@mixin i-f-c {
  @include i-f;
  flex-direction: column;
}

@mixin i-f-c-($gap) {
  @include i-f-c;
  @include r-g-($gap);
}

@mixin ai-st {align-items: stretch;}
@mixin ai-fs {align-items: flex-start;}
@mixin ai-s {align-items: start;}
@mixin ai-fe {align-items: flex-end;}
@mixin ai-e {align-items: end;}
@mixin ai-c {align-items: center;}
@mixin ai-b {align-items: baseline;}
@mixin ai-sb {align-items: space-between;}

@mixin jc-fs {justify-content: flex-start;}
@mixin jc-fe {justify-content: flex-end;}
@mixin jc-c {justify-content: center;}
@mixin jc-s {justify-content: start;}
@mixin jc-e {justify-content: end;}
@mixin jc-l {justify-content: left;}
@mixin jc-r {justify-content: right;}
@mixin jc-sb {justify-content: space-between;}
@mixin jc-sa {justify-content: space-around;}
@mixin jc-se {justify-content: space-evenly;}

@mixin f-cc {
  @include f;
  @include jc-c;
  @include ai-c;
}

@mixin fr-cc {
  @include f-r;
  @include jc-c;
  @include ai-c;
}

@mixin fc-cc {
  @include f-c;
  @include jc-c;
  @include ai-c;
}

@mixin i-f-cc {
  @include i-f;
  @include jc-c;
  @include ai-c;
}

@mixin st-fs {
  @include jc-fs;
  @include ai-st;
}

@mixin st-fe {
  @include jc-fe;
  @include ai-st;
}

@mixin st-s {
  @include jc-s;
  @include ai-st;
}

@mixin st-e {
  @include jc-e;
  @include ai-st;
}

@mixin st-l {
  @include jc-l;
  @include ai-st;
}

@mixin st-r {
  @include jc-r;
  @include ai-st;
}

@mixin st-sb {
  @include jc-sb;
  @include ai-st;
}

@mixin st-sa {
  @include jc-sa;
  @include ai-st;
}

@mixin st-se {
  @include jc-se;
  @include ai-st;
}

@mixin fs-fs {
  @include jc-fs;
  @include ai-fs;
}

@mixin fs-fe {
  @include jc-fe;
  @include ai-fs;
}

@mixin fs-s {
  @include jc-s;
  @include ai-fs;
}

@mixin fs-e {
  @include jc-e;
  @include ai-fs;
}

@mixin fs-l {
  @include jc-l;
  @include ai-fs;
}

@mixin fs-r {
  @include jc-r;
  @include ai-fs;
}

@mixin fs-sb {
  @include jc-sb;
  @include ai-fs;
}

@mixin fs-sa {
  @include jc-sa;
  @include ai-fs;
}

@mixin fs-se {
  @include jc-se;
  @include ai-fs;
}

@mixin s-fs {
  @include jc-fs;
  @include ai-s;
}

@mixin s-fe {
  @include jc-fe;
  @include ai-s;
}

@mixin s-s {
  @include jc-s;
  @include ai-s;
}

@mixin s-e {
  @include jc-e;
  @include ai-s;
}

@mixin s-l {
  @include jc-l;
  @include ai-s;
}

@mixin s-r {
  @include jc-r;
  @include ai-s;
}

@mixin s-sb {
  @include jc-sb;
  @include ai-s;
}

@mixin s-sa {
  @include jc-sa;
  @include ai-s;
}

@mixin s-se {
  @include jc-se;
  @include ai-s;
}

@mixin fe-fs {
  @include jc-fs;
  @include ai-fe;
}

@mixin fe-fe {
  @include jc-fe;
  @include ai-fe;
}

@mixin fe-s {
  @include jc-s;
  @include ai-fe;
}

@mixin fe-e {
  @include jc-e;
  @include ai-fe;
}

@mixin fe-l {
  @include jc-l;
  @include ai-fe;
}

@mixin fe-r {
  @include jc-r;
  @include ai-fe;
}

@mixin fe-sb {
  @include jc-sb;
  @include ai-fe;
}

@mixin fe-sa {
  @include jc-sa;
  @include ai-fe;
}

@mixin fe-se {
  @include jc-se;
  @include ai-fe;
}

@mixin e-fs {
  @include jc-fs;
  @include ai-e;
}

@mixin e-fe {
  @include jc-fe;
  @include ai-e;
}

@mixin e-s {
  @include jc-s;
  @include ai-e;
}

@mixin e-e {
  @include jc-e;
  @include ai-e;
}

@mixin e-l {
  @include jc-l;
  @include ai-e;
}

@mixin e-r {
  @include jc-r;
  @include ai-e;
}

@mixin e-sb {
  @include jc-sb;
  @include ai-e;
}

@mixin e-sa {
  @include jc-sa;
  @include ai-e;
}

@mixin e-se {
  @include jc-se;
  @include ai-e;
}

@mixin c-fs {
  @include jc-fs;
  @include ai-c;
}

@mixin c-fe {
  @include jc-fe;
  @include ai-c;
}

@mixin c-s {
  @include jc-s;
  @include ai-c;
}

@mixin c-e {
  @include jc-e;
  @include ai-c;
}

@mixin c-l {
  @include jc-l;
  @include ai-c;
}

@mixin c-r {
  @include jc-r;
  @include ai-c;
}

@mixin c-sb {
  @include jc-sb;
  @include ai-c;
}

@mixin c-sa {
  @include jc-sa;
  @include ai-c;
}

@mixin c-se {
  @include jc-se;
  @include ai-c;
}

@mixin b-fs {
  @include jc-fs;
  @include ai-b;
}

@mixin b-fe {
  @include jc-fe;
  @include ai-b;
}

@mixin b-s {
  @include jc-s;
  @include ai-b;
}

@mixin b-e {
  @include jc-e;
  @include ai-b;
}

@mixin b-l {
  @include jc-l;
  @include ai-b;
}

@mixin b-r {
  @include jc-r;
  @include ai-b;
}

@mixin b-sb {
  @include jc-sb;
  @include ai-b;
}

@mixin b-sa {
  @include jc-sa;
  @include ai-b;
}

@mixin b-se {
  @include jc-se;
  @include ai-b;
}

@mixin c-c {
  @include jc-c;
  @include ai-c;
}

@mixin fs-c {
  @include jc-c;
  @include ai-fs;
}

@mixin sb-fs {
  @include jc-fs;
  @include ai-sb;
}

@mixin st-c {
  @include jc-c;
  @include ai-st;
}

@mixin fe-c {
  @include jc-c;
  @include ai-fe;
}

@mixin g {display: grid;}
@mixin i-g {display: -ms-inline-grid;display: -moz-inline-grid;}

@mixin g-c-($columns) {
  grid-template-columns: $columns;
}

@mixin g-r-($rows) {
  grid-template-rows: $rows;
}

@mixin g-a-($areas) {
  grid-template-areas: $areas;
}

@mixin g-($columns,$rows,$areas) {
  @include g;
  @include g-c-($columns);
  @include g-r-($rows);
  @include g-a-($areas);
}

@mixin i-g-($columns,$rows,$areas) {
  @include i-g;
  @include g-c-($columns);
  @include g-r-($rows);
  @include g-a-($areas);
}

@mixin a-($area) {
  grid-area: $area;
}

@mixin h-f {height: fit-content;}
@mixin h-min {height: min-content;}
@mixin h-max {height: max-content;}
@mixin w-f {width: fit-content;}
@mixin w-min {width: min-content;}
@mixin w-max {width: max-content;}

@mixin h-i {@include h-min;}
@mixin h-a {@include h-max;}
@mixin w-i {@include w-min;}
@mixin w-a {@include w-max;}

@mixin h-w-f {
  @include h-f;
  @include w-f;
}

@mixin h-w-min {
  @include h-i;
  @include w-i;
}

@mixin h-w-max {
  @include h-a;
  @include w-a;
}

@mixin h-w-f-min {
  @include h-f;
  @include w-i;
}

@mixin h-w-min-f {
  @include h-i;
  @include w-f;
}

@mixin h-w-f-max {
  @include h-f;
  @include w-a;
}

@mixin h-w-max-f {
  @include h-a;
  @include w-f;
}

@mixin h-w-min-max {
  @include h-i;
  @include w-a;
}

@mixin h-w-max-min {
  @include h-a;
  @include w-i;
}

@mixin h-w-i {
  @include h-w-min;
}

@mixin h-w-a {
  @include h-w-max;
}

@mixin h-w-f-i {
  @include h-w-f-min;
}

@mixin h-w-i-f {
  @include h-w-min-f;
}

@mixin h-w-f-a {
  @include h-w-f-max;
}

@mixin h-w-a-f {
  @include h-w-max-f;
}

@mixin h-w-i-a {
  @include h-w-min-max;
}

@mixin h-w-a-i {
  @include h-w-max-min;
}

@mixin xs {
  @media (min-width: $xs) {@content;}
}
@mixin sm {
  @media (min-width: $sm) {@content;}
}
@mixin md {
  @media (min-width: $md) {@content;}
}
@mixin lg {
  @media (min-width: $lg) {@content;}
}
@mixin xl {
  @media (min-width: $xl) {@content;}
}
@mixin xxl {
  @media (min-width: $xxl) {@content;}
}

@mixin xs-dn {
  @media (max-width: 576px) {@content;}
}
@mixin sm-dn {
  @media (max-width: 576px) {@content;}
}
@mixin md-dn {
  @media (max-width: 768px) {@content;}
}
@mixin lg-dn {
  @media (max-width: 992px) {@content;}
}
@mixin xl-dn {
  @media (max-width: 1200px) {@content;}
}
@mixin xxl-dn {
  @media (max-width: 1200px) {@content;}
}

@mixin xs-n {
  @include xs {
    display: none;
  }
}
@mixin sm-n {
  @include sm {
    display: none;
  }
}
@mixin md-n {
  @include md {
    display: none;
  }
}
@mixin lg-n {
  @include lg {
    display: none;
  }
}
@mixin xl-n {
  @include xl {
    display: none;
  }
}
@mixin xxl-n {
  @include xxl {
    display: none;
  }
}

@mixin n-xs {
  @include xs-dn {
    display: none;
  }
}
@mixin n-sm {
  @include sm-dn {
    display: none;
  }
}
@mixin n-md {
  @include md-dn {
    display: none;
  }
}
@mixin n-lg {
  @include lg-dn {
    display: none;
  }
}
@mixin n-xl {
  @include xl-dn {
    display: none;
  }
}
@mixin n-xxl {
  @include xxl-dn {
    display: none;
  }
}

@mixin xs-b {
  @include xs {
    display: block;
  }
}
@mixin sm-b {
  @include sm {
    display: block;
  }
}
@mixin md-b {
  @include md {
    display: block;
  }
}
@mixin lg-b {
  @include lg {
    display: block;
  }
}
@mixin xl-b {
  @include xl {
    display: block;
  }
}

@mixin xs-f {
  @include xs {
    display: flex;
  }
}
@mixin sm-f {
  @include sm {
    display: flex;
  }
}
@mixin md-f {
  @include md {
    display: flex;
  }
}
@mixin lg-f {
  @include lg {
    display: flex;
  }
}
@mixin xl-f {
  @include xl {
    display: flex;
  }
}

@mixin xs-i {
  @include xs {
    display: inline;
  }
}
@mixin sm-i {
  @include sm {
    display: inline;
  }
}
@mixin md-i {
  @include md {
    display: inline;
  }
}
@mixin lg-i {
  @include lg {
    display: inline;
  }
}
@mixin xl-i {
  @include xl {
    display: inline;
  }
}

@mixin xs-ib {
  @include xs {
    display: inline-block;
  }
}
@mixin sm-ib {
  @include sm {
    display: inline-block;
  }
}
@mixin md-ib {
  @include md {
    display: inline-block;
  }
}
@mixin lg-ib {
  @include lg {
    display: inline-block;
  }
}
@mixin xl-ib {
  @include xl {
    display: inline-block;
  }
}

@mixin f-r-xs {
  @include xs {
    @include f-r;
  }
  @include sm {
    @include f-c;
  }
}
@mixin f-r-sm {
  @include xs {
    @include f-r;
  }
  @include md {
    @include f-c;
  }
}
@mixin f-r-md {
  @include xs {
    @include f-r;
  }
  @include lg {
    @include f-c;
  }
}
@mixin f-r-lg {
  @include xs {
    @include f-r;
  }
  @include xl {
    @include f-c;
  }
}
@mixin f-r-xl {
  @include xs {
    @include f-r;
  }
  @include xxl {
    @include f-c;
  }
}

@mixin f-c-xs {
  @include xs {
    @include f-c;
  }
  @include sm {
    @include f-r;
  }
}
@mixin f-c-sm {
  @include xs {
    @include f-c;
  }
  @include md {
    @include f-r;
  }
}
@mixin f-c-md {
  @include xs {
    @include f-c;
  }
  @include lg {
    @include f-r;
  }
}
@mixin f-c-lg {
  @include xs {
    @include f-c;
  }
  @include xl {
    @include f-r;
  }
}
@mixin f-c-xl {
  @include xs {
    @include f-c;
  }
  @include xxl {
    @include f-r;
  }
}

@mixin f-r-xs-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include sm {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-sm-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include md {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-md-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include lg {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-lg-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include xl {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}
@mixin f-r-xl-($gap1, $gap2: -1) {
  @include xs {
    @include f-r-($gap1);
  }
  @include xxl {
    @if $gap2 == -1 {
      @include f-c-($gap1);
    } @else {
      @include f-c-($gap2);
    }
  }
}

@mixin f-c-xs-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include sm {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-sm-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include md {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-md-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include lg {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-lg-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include xl {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}
@mixin f-c-xl-($gap1, $gap2: -1) {
  @include xs {
    @include f-c-($gap1);
  }
  @include xxl {
    @if $gap2 == -1 {
      @include f-r-($gap1);
    } @else {
      @include f-r-($gap2);
    }
  }
}

// ###########################################################################################################################

@mixin upper-alignment {
  &xs {
    @include xs {
      &-st-fs {@include st-fs;}
      &-st-fe {@include st-fe;}
      &-st-s {@include st-s;}
      &-st-e {@include st-e;}
      &-st-l {@include st-l;}
      &-st-r {@include st-r;}
      &-st-sb {@include st-sb;}
      &-st-sa {@include st-sa;}
      &-st-se {@include st-se;}
      &-fs-fs {@include fs-fs;}
      &-fs-fe {@include fs-fe;}
      &-fs-s {@include fs-s;}
      &-fs-e {@include fs-e;}
      &-fs-l {@include fs-l;}
      &-fs-r {@include fs-r;}
      &-fs-sb {@include fs-sb;}
      &-fs-sa {@include fs-sa;}
      &-fs-se {@include fs-se;}
      &-s-fs {@include s-fs;}
      &-s-fe {@include s-fe;}
      &-s-s {@include s-s;}
      &-s-e {@include s-e;}
      &-s-l {@include s-l;}
      &-s-r {@include s-r;}
      &-s-sb {@include s-sb;}
      &-s-sa {@include s-sa;}
      &-s-se {@include s-se;}
      &-fe-fs {@include fe-fs;}
      &-fe-fe {@include fe-fe;}
      &-fe-s {@include fe-s;}
      &-fe-e {@include fe-e;}
      &-fe-l {@include fe-l;}
      &-fe-r {@include fe-r;}
      &-fe-sb {@include fe-sb;}
      &-fe-sa {@include fe-sa;}
      &-fe-se {@include fe-se;}
      &-e-fs {@include e-fs;}
      &-e-fe {@include e-fe;}
      &-e-s {@include e-s;}
      &-e-e {@include e-e;}
      &-e-l {@include e-l;}
      &-e-r {@include e-r;}
      &-e-sb {@include e-sb;}
      &-e-sa {@include e-sa;}
      &-e-se {@include e-se;}
      &-c-fs {@include c-fs;}
      &-c-fe {@include c-fe;}
      &-c-s {@include c-s;}
      &-c-e {@include c-e;}
      &-c-l {@include c-l;}
      &-c-r {@include c-r;}
      &-c-sb {@include c-sb;}
      &-c-sa {@include c-sa;}
      &-c-se {@include c-se;}
      &-b-fs {@include b-fs;}
      &-b-fe {@include b-fe;}
      &-b-s {@include b-s;}
      &-b-e {@include b-e;}
      &-b-l {@include b-l;}
      &-b-r {@include b-r;}
      &-b-sb {@include b-sb;}
      &-b-sa {@include b-sa;}
      &-b-se {@include b-se;}
      &-c-c {@include c-c;}
      &-fs-c {@include fs-c;}
      &-sb-fs {@include sb-fs;}
      &-st-c {@include st-c;}
      &-fe-c {@include fe-c;}
    }
  }
  &sm {
    @include sm {
      &-st-fs {@include st-fs;}
      &-st-fe {@include st-fe;}
      &-st-s {@include st-s;}
      &-st-e {@include st-e;}
      &-st-l {@include st-l;}
      &-st-r {@include st-r;}
      &-st-sb {@include st-sb;}
      &-st-sa {@include st-sa;}
      &-st-se {@include st-se;}
      &-fs-fs {@include fs-fs;}
      &-fs-fe {@include fs-fe;}
      &-fs-s {@include fs-s;}
      &-fs-e {@include fs-e;}
      &-fs-l {@include fs-l;}
      &-fs-r {@include fs-r;}
      &-fs-sb {@include fs-sb;}
      &-fs-sa {@include fs-sa;}
      &-fs-se {@include fs-se;}
      &-s-fs {@include s-fs;}
      &-s-fe {@include s-fe;}
      &-s-s {@include s-s;}
      &-s-e {@include s-e;}
      &-s-l {@include s-l;}
      &-s-r {@include s-r;}
      &-s-sb {@include s-sb;}
      &-s-sa {@include s-sa;}
      &-s-se {@include s-se;}
      &-fe-fs {@include fe-fs;}
      &-fe-fe {@include fe-fe;}
      &-fe-s {@include fe-s;}
      &-fe-e {@include fe-e;}
      &-fe-l {@include fe-l;}
      &-fe-r {@include fe-r;}
      &-fe-sb {@include fe-sb;}
      &-fe-sa {@include fe-sa;}
      &-fe-se {@include fe-se;}
      &-e-fs {@include e-fs;}
      &-e-fe {@include e-fe;}
      &-e-s {@include e-s;}
      &-e-e {@include e-e;}
      &-e-l {@include e-l;}
      &-e-r {@include e-r;}
      &-e-sb {@include e-sb;}
      &-e-sa {@include e-sa;}
      &-e-se {@include e-se;}
      &-c-fs {@include c-fs;}
      &-c-fe {@include c-fe;}
      &-c-s {@include c-s;}
      &-c-e {@include c-e;}
      &-c-l {@include c-l;}
      &-c-r {@include c-r;}
      &-c-sb {@include c-sb;}
      &-c-sa {@include c-sa;}
      &-c-se {@include c-se;}
      &-b-fs {@include b-fs;}
      &-b-fe {@include b-fe;}
      &-b-s {@include b-s;}
      &-b-e {@include b-e;}
      &-b-l {@include b-l;}
      &-b-r {@include b-r;}
      &-b-sb {@include b-sb;}
      &-b-sa {@include b-sa;}
      &-b-se {@include b-se;}
      &-c-c {@include c-c;}
      &-fs-c {@include fs-c;}
      &-sb-fs {@include sb-fs;}
      &-st-c {@include st-c;}
      &-fe-c {@include fe-c;}
    }
  }
  &md {
    @include md {
      &-st-fs {@include st-fs;}
      &-st-fe {@include st-fe;}
      &-st-s {@include st-s;}
      &-st-e {@include st-e;}
      &-st-l {@include st-l;}
      &-st-r {@include st-r;}
      &-st-sb {@include st-sb;}
      &-st-sa {@include st-sa;}
      &-st-se {@include st-se;}
      &-fs-fs {@include fs-fs;}
      &-fs-fe {@include fs-fe;}
      &-fs-s {@include fs-s;}
      &-fs-e {@include fs-e;}
      &-fs-l {@include fs-l;}
      &-fs-r {@include fs-r;}
      &-fs-sb {@include fs-sb;}
      &-fs-sa {@include fs-sa;}
      &-fs-se {@include fs-se;}
      &-s-fs {@include s-fs;}
      &-s-fe {@include s-fe;}
      &-s-s {@include s-s;}
      &-s-e {@include s-e;}
      &-s-l {@include s-l;}
      &-s-r {@include s-r;}
      &-s-sb {@include s-sb;}
      &-s-sa {@include s-sa;}
      &-s-se {@include s-se;}
      &-fe-fs {@include fe-fs;}
      &-fe-fe {@include fe-fe;}
      &-fe-s {@include fe-s;}
      &-fe-e {@include fe-e;}
      &-fe-l {@include fe-l;}
      &-fe-r {@include fe-r;}
      &-fe-sb {@include fe-sb;}
      &-fe-sa {@include fe-sa;}
      &-fe-se {@include fe-se;}
      &-e-fs {@include e-fs;}
      &-e-fe {@include e-fe;}
      &-e-s {@include e-s;}
      &-e-e {@include e-e;}
      &-e-l {@include e-l;}
      &-e-r {@include e-r;}
      &-e-sb {@include e-sb;}
      &-e-sa {@include e-sa;}
      &-e-se {@include e-se;}
      &-c-fs {@include c-fs;}
      &-c-fe {@include c-fe;}
      &-c-s {@include c-s;}
      &-c-e {@include c-e;}
      &-c-l {@include c-l;}
      &-c-r {@include c-r;}
      &-c-sb {@include c-sb;}
      &-c-sa {@include c-sa;}
      &-c-se {@include c-se;}
      &-b-fs {@include b-fs;}
      &-b-fe {@include b-fe;}
      &-b-s {@include b-s;}
      &-b-e {@include b-e;}
      &-b-l {@include b-l;}
      &-b-r {@include b-r;}
      &-b-sb {@include b-sb;}
      &-b-sa {@include b-sa;}
      &-b-se {@include b-se;}
      &-c-c {@include c-c;}
      &-fs-c {@include fs-c;}
      &-sb-fs {@include sb-fs;}
      &-st-c {@include st-c;}
      &-fe-c {@include fe-c;}
    }
  }
  &lg {
    @include lg {
      &-st-fs {@include st-fs;}
      &-st-fe {@include st-fe;}
      &-st-s {@include st-s;}
      &-st-e {@include st-e;}
      &-st-l {@include st-l;}
      &-st-r {@include st-r;}
      &-st-sb {@include st-sb;}
      &-st-sa {@include st-sa;}
      &-st-se {@include st-se;}
      &-fs-fs {@include fs-fs;}
      &-fs-fe {@include fs-fe;}
      &-fs-s {@include fs-s;}
      &-fs-e {@include fs-e;}
      &-fs-l {@include fs-l;}
      &-fs-r {@include fs-r;}
      &-fs-sb {@include fs-sb;}
      &-fs-sa {@include fs-sa;}
      &-fs-se {@include fs-se;}
      &-s-fs {@include s-fs;}
      &-s-fe {@include s-fe;}
      &-s-s {@include s-s;}
      &-s-e {@include s-e;}
      &-s-l {@include s-l;}
      &-s-r {@include s-r;}
      &-s-sb {@include s-sb;}
      &-s-sa {@include s-sa;}
      &-s-se {@include s-se;}
      &-fe-fs {@include fe-fs;}
      &-fe-fe {@include fe-fe;}
      &-fe-s {@include fe-s;}
      &-fe-e {@include fe-e;}
      &-fe-l {@include fe-l;}
      &-fe-r {@include fe-r;}
      &-fe-sb {@include fe-sb;}
      &-fe-sa {@include fe-sa;}
      &-fe-se {@include fe-se;}
      &-e-fs {@include e-fs;}
      &-e-fe {@include e-fe;}
      &-e-s {@include e-s;}
      &-e-e {@include e-e;}
      &-e-l {@include e-l;}
      &-e-r {@include e-r;}
      &-e-sb {@include e-sb;}
      &-e-sa {@include e-sa;}
      &-e-se {@include e-se;}
      &-c-fs {@include c-fs;}
      &-c-fe {@include c-fe;}
      &-c-s {@include c-s;}
      &-c-e {@include c-e;}
      &-c-l {@include c-l;}
      &-c-r {@include c-r;}
      &-c-sb {@include c-sb;}
      &-c-sa {@include c-sa;}
      &-c-se {@include c-se;}
      &-b-fs {@include b-fs;}
      &-b-fe {@include b-fe;}
      &-b-s {@include b-s;}
      &-b-e {@include b-e;}
      &-b-l {@include b-l;}
      &-b-r {@include b-r;}
      &-b-sb {@include b-sb;}
      &-b-sa {@include b-sa;}
      &-b-se {@include b-se;}
      &-c-c {@include c-c;}
      &-fs-c {@include fs-c;}
      &-sb-fs {@include sb-fs;}
      &-st-c {@include st-c;}
      &-fe-c {@include fe-c;}
    }
  }
  &xl {
    @include xl {
      &-st-fs {@include st-fs;}
      &-st-fe {@include st-fe;}
      &-st-s {@include st-s;}
      &-st-e {@include st-e;}
      &-st-l {@include st-l;}
      &-st-r {@include st-r;}
      &-st-sb {@include st-sb;}
      &-st-sa {@include st-sa;}
      &-st-se {@include st-se;}
      &-fs-fs {@include fs-fs;}
      &-fs-fe {@include fs-fe;}
      &-fs-s {@include fs-s;}
      &-fs-e {@include fs-e;}
      &-fs-l {@include fs-l;}
      &-fs-r {@include fs-r;}
      &-fs-sb {@include fs-sb;}
      &-fs-sa {@include fs-sa;}
      &-fs-se {@include fs-se;}
      &-s-fs {@include s-fs;}
      &-s-fe {@include s-fe;}
      &-s-s {@include s-s;}
      &-s-e {@include s-e;}
      &-s-l {@include s-l;}
      &-s-r {@include s-r;}
      &-s-sb {@include s-sb;}
      &-s-sa {@include s-sa;}
      &-s-se {@include s-se;}
      &-fe-fs {@include fe-fs;}
      &-fe-fe {@include fe-fe;}
      &-fe-s {@include fe-s;}
      &-fe-e {@include fe-e;}
      &-fe-l {@include fe-l;}
      &-fe-r {@include fe-r;}
      &-fe-sb {@include fe-sb;}
      &-fe-sa {@include fe-sa;}
      &-fe-se {@include fe-se;}
      &-e-fs {@include e-fs;}
      &-e-fe {@include e-fe;}
      &-e-s {@include e-s;}
      &-e-e {@include e-e;}
      &-e-l {@include e-l;}
      &-e-r {@include e-r;}
      &-e-sb {@include e-sb;}
      &-e-sa {@include e-sa;}
      &-e-se {@include e-se;}
      &-c-fs {@include c-fs;}
      &-c-fe {@include c-fe;}
      &-c-s {@include c-s;}
      &-c-e {@include c-e;}
      &-c-l {@include c-l;}
      &-c-r {@include c-r;}
      &-c-sb {@include c-sb;}
      &-c-sa {@include c-sa;}
      &-c-se {@include c-se;}
      &-b-fs {@include b-fs;}
      &-b-fe {@include b-fe;}
      &-b-s {@include b-s;}
      &-b-e {@include b-e;}
      &-b-l {@include b-l;}
      &-b-r {@include b-r;}
      &-b-sb {@include b-sb;}
      &-b-sa {@include b-sa;}
      &-b-se {@include b-se;}
      &-c-c {@include c-c;}
      &-fs-c {@include fs-c;}
      &-sb-fs {@include sb-fs;}
      &-st-c {@include st-c;}
      &-fe-c {@include fe-c;}
    }
  }
  &xxl {
    @include xxl {
      &-st-fs {@include st-fs;}
      &-st-fe {@include st-fe;}
      &-st-s {@include st-s;}
      &-st-e {@include st-e;}
      &-st-l {@include st-l;}
      &-st-r {@include st-r;}
      &-st-sb {@include st-sb;}
      &-st-sa {@include st-sa;}
      &-st-se {@include st-se;}
      &-fs-fs {@include fs-fs;}
      &-fs-fe {@include fs-fe;}
      &-fs-s {@include fs-s;}
      &-fs-e {@include fs-e;}
      &-fs-l {@include fs-l;}
      &-fs-r {@include fs-r;}
      &-fs-sb {@include fs-sb;}
      &-fs-sa {@include fs-sa;}
      &-fs-se {@include fs-se;}
      &-s-fs {@include s-fs;}
      &-s-fe {@include s-fe;}
      &-s-s {@include s-s;}
      &-s-e {@include s-e;}
      &-s-l {@include s-l;}
      &-s-r {@include s-r;}
      &-s-sb {@include s-sb;}
      &-s-sa {@include s-sa;}
      &-s-se {@include s-se;}
      &-fe-fs {@include fe-fs;}
      &-fe-fe {@include fe-fe;}
      &-fe-s {@include fe-s;}
      &-fe-e {@include fe-e;}
      &-fe-l {@include fe-l;}
      &-fe-r {@include fe-r;}
      &-fe-sb {@include fe-sb;}
      &-fe-sa {@include fe-sa;}
      &-fe-se {@include fe-se;}
      &-e-fs {@include e-fs;}
      &-e-fe {@include e-fe;}
      &-e-s {@include e-s;}
      &-e-e {@include e-e;}
      &-e-l {@include e-l;}
      &-e-r {@include e-r;}
      &-e-sb {@include e-sb;}
      &-e-sa {@include e-sa;}
      &-e-se {@include e-se;}
      &-c-fs {@include c-fs;}
      &-c-fe {@include c-fe;}
      &-c-s {@include c-s;}
      &-c-e {@include c-e;}
      &-c-l {@include c-l;}
      &-c-r {@include c-r;}
      &-c-sb {@include c-sb;}
      &-c-sa {@include c-sa;}
      &-c-se {@include c-se;}
      &-b-fs {@include b-fs;}
      &-b-fe {@include b-fe;}
      &-b-s {@include b-s;}
      &-b-e {@include b-e;}
      &-b-l {@include b-l;}
      &-b-r {@include b-r;}
      &-b-sb {@include b-sb;}
      &-b-sa {@include b-sa;}
      &-b-se {@include b-se;}
      &-c-c {@include c-c;}
      &-fs-c {@include fs-c;}
      &-sb-fs {@include sb-fs;}
      &-st-c {@include st-c;}
      &-fe-c {@include fe-c;}
    }
  }
  &bp {
    @include bp {
      &-st-fs {@include st-fs;}
      &-st-fe {@include st-fe;}
      &-st-s {@include st-s;}
      &-st-e {@include st-e;}
      &-st-l {@include st-l;}
      &-st-r {@include st-r;}
      &-st-sb {@include st-sb;}
      &-st-sa {@include st-sa;}
      &-st-se {@include st-se;}
      &-fs-fs {@include fs-fs;}
      &-fs-fe {@include fs-fe;}
      &-fs-s {@include fs-s;}
      &-fs-e {@include fs-e;}
      &-fs-l {@include fs-l;}
      &-fs-r {@include fs-r;}
      &-fs-sb {@include fs-sb;}
      &-fs-sa {@include fs-sa;}
      &-fs-se {@include fs-se;}
      &-s-fs {@include s-fs;}
      &-s-fe {@include s-fe;}
      &-s-s {@include s-s;}
      &-s-e {@include s-e;}
      &-s-l {@include s-l;}
      &-s-r {@include s-r;}
      &-s-sb {@include s-sb;}
      &-s-sa {@include s-sa;}
      &-s-se {@include s-se;}
      &-fe-fs {@include fe-fs;}
      &-fe-fe {@include fe-fe;}
      &-fe-s {@include fe-s;}
      &-fe-e {@include fe-e;}
      &-fe-l {@include fe-l;}
      &-fe-r {@include fe-r;}
      &-fe-sb {@include fe-sb;}
      &-fe-sa {@include fe-sa;}
      &-fe-se {@include fe-se;}
      &-e-fs {@include e-fs;}
      &-e-fe {@include e-fe;}
      &-e-s {@include e-s;}
      &-e-e {@include e-e;}
      &-e-l {@include e-l;}
      &-e-r {@include e-r;}
      &-e-sb {@include e-sb;}
      &-e-sa {@include e-sa;}
      &-e-se {@include e-se;}
      &-c-fs {@include c-fs;}
      &-c-fe {@include c-fe;}
      &-c-s {@include c-s;}
      &-c-e {@include c-e;}
      &-c-l {@include c-l;}
      &-c-r {@include c-r;}
      &-c-sb {@include c-sb;}
      &-c-sa {@include c-sa;}
      &-c-se {@include c-se;}
      &-b-fs {@include b-fs;}
      &-b-fe {@include b-fe;}
      &-b-s {@include b-s;}
      &-b-e {@include b-e;}
      &-b-l {@include b-l;}
      &-b-r {@include b-r;}
      &-b-sb {@include b-sb;}
      &-b-sa {@include b-sa;}
      &-b-se {@include b-se;}
      &-c-c {@include c-c;}
      &-fs-c {@include fs-c;}
      &-sb-fs {@include sb-fs;}
      &-st-c {@include st-c;}
      &-fe-c {@include fe-c;}
    }
  }
}

@mixin st-fs- {
  @include xs {
    @include st-fs;
  }
  @include upper-alignment;
}
@mixin st-fe- {
  @include xs {
    @include st-fe;
  }
  @include upper-alignment;
}
@mixin st-s- {
  @include xs {
    @include st-s;
  }
  @include upper-alignment;
}
@mixin st-e- {
  @include xs {
    @include st-e;
  }
  @include upper-alignment;
}
@mixin st-l- {
  @include xs {
    @include st-l;
  }
  @include upper-alignment;
}
@mixin st-r- {
  @include xs {
    @include st-r;
  }
  @include upper-alignment;
}
@mixin st-sb- {
  @include xs {
    @include st-sb;
  }
  @include upper-alignment;
}
@mixin st-sa- {
  @include xs {
    @include st-sa;
  }
  @include upper-alignment;
}
@mixin st-se- {
  @include xs {
    @include st-se;
  }
  @include upper-alignment;
}
@mixin fs-fs- {
  @include xs {
    @include fs-fs;
  }
  @include upper-alignment;
}
@mixin fs-fe- {
  @include xs {
    @include fs-fe;
  }
  @include upper-alignment;
}
@mixin fs-s- {
  @include xs {
    @include fs-s;
  }
  @include upper-alignment;
}
@mixin fs-e- {
  @include xs {
    @include fs-e;
  }
  @include upper-alignment;
}
@mixin fs-l- {
  @include xs {
    @include fs-l;
  }
  @include upper-alignment;
}
@mixin fs-r- {
  @include xs {
    @include fs-r;
  }
  @include upper-alignment;
}
@mixin fs-sb- {
  @include xs {
    @include fs-sb;
  }
  @include upper-alignment;
}
@mixin fs-sa- {
  @include xs {
    @include fs-sa;
  }
  @include upper-alignment;
}
@mixin fs-se- {
  @include xs {
    @include fs-se;
  }
  @include upper-alignment;
}
@mixin s-fs- {
  @include xs {
    @include s-fs;
  }
  @include upper-alignment;
}
@mixin s-fe- {
  @include xs {
    @include s-fe;
  }
  @include upper-alignment;
}
@mixin s-s- {
  @include xs {
    @include s-s;
  }
  @include upper-alignment;
}
@mixin s-e- {
  @include xs {
    @include s-e;
  }
  @include upper-alignment;
}
@mixin s-l- {
  @include xs {
    @include s-l;
  }
  @include upper-alignment;
}
@mixin s-r- {
  @include xs {
    @include s-r;
  }
  @include upper-alignment;
}
@mixin s-sb- {
  @include xs {
    @include s-sb;
  }
  @include upper-alignment;
}
@mixin s-sa- {
  @include xs {
    @include s-sa;
  }
  @include upper-alignment;
}
@mixin s-se- {
  @include xs {
    @include s-se;
  }
  @include upper-alignment;
}
@mixin fe-fs- {
  @include xs {
    @include fe-fs;
  }
  @include upper-alignment;
}
@mixin fe-fe- {
  @include xs {
    @include fe-fe;
  }
  @include upper-alignment;
}
@mixin fe-s- {
  @include xs {
    @include fe-s;
  }
  @include upper-alignment;
}
@mixin fe-e- {
  @include xs {
    @include fe-e;
  }
  @include upper-alignment;
}
@mixin fe-l- {
  @include xs {
    @include fe-l;
  }
  @include upper-alignment;
}
@mixin fe-r- {
  @include xs {
    @include fe-r;
  }
  @include upper-alignment;
}
@mixin fe-sb- {
  @include xs {
    @include fe-sb;
  }
  @include upper-alignment;
}
@mixin fe-sa- {
  @include xs {
    @include fe-sa;
  }
  @include upper-alignment;
}
@mixin fe-se- {
  @include xs {
    @include fe-se;
  }
  @include upper-alignment;
}
@mixin e-fs- {
  @include xs {
    @include e-fs;
  }
  @include upper-alignment;
}
@mixin e-fe- {
  @include xs {
    @include e-fe;
  }
  @include upper-alignment;
}
@mixin e-s- {
  @include xs {
    @include e-s;
  }
  @include upper-alignment;
}
@mixin e-e- {
  @include xs {
    @include e-e;
  }
  @include upper-alignment;
}
@mixin e-l- {
  @include xs {
    @include e-l;
  }
  @include upper-alignment;
}
@mixin e-r- {
  @include xs {
    @include e-r;
  }
  @include upper-alignment;
}
@mixin e-sb- {
  @include xs {
    @include e-sb;
  }
  @include upper-alignment;
}
@mixin e-sa- {
  @include xs {
    @include e-sa;
  }
  @include upper-alignment;
}
@mixin e-se- {
  @include xs {
    @include e-se;
  }
  @include upper-alignment;
}
@mixin c-fs- {
  @include xs {
    @include c-fs;
  }
  @include upper-alignment;
}
@mixin c-fe- {
  @include xs {
    @include c-fe;
  }
  @include upper-alignment;
}
@mixin c-s- {
  @include xs {
    @include c-s;
  }
  @include upper-alignment;
}
@mixin c-e- {
  @include xs {
    @include c-e;
  }
  @include upper-alignment;
}
@mixin c-l- {
  @include xs {
    @include c-l;
  }
  @include upper-alignment;
}
@mixin c-r- {
  @include xs {
    @include c-r;
  }
  @include upper-alignment;
}
@mixin c-sb- {
  @include xs {
    @include c-sb;
  }
  @include upper-alignment;
}
@mixin c-sa- {
  @include xs {
    @include c-sa;
  }
  @include upper-alignment;
}
@mixin c-se- {
  @include xs {
    @include c-se;
  }
  @include upper-alignment;
}
@mixin b-fs- {
  @include xs {
    @include b-fs;
  }
  @include upper-alignment;
}
@mixin b-fe- {
  @include xs {
    @include b-fe;
  }
  @include upper-alignment;
}
@mixin b-s- {
  @include xs {
    @include b-s;
  }
  @include upper-alignment;
}
@mixin b-e- {
  @include xs {
    @include b-e;
  }
  @include upper-alignment;
}
@mixin b-l- {
  @include xs {
    @include b-l;
  }
  @include upper-alignment;
}
@mixin b-r- {
  @include xs {
    @include b-r;
  }
  @include upper-alignment;
}
@mixin b-sb- {
  @include xs {
    @include b-sb;
  }
  @include upper-alignment;
}
@mixin b-sa- {
  @include xs {
    @include b-sa;
  }
  @include upper-alignment;
}
@mixin b-se- {
  @include xs {
    @include b-se;
  }
  @include upper-alignment;
}
@mixin c-c- {
  @include xs {
    @include c-c;
  }
  @include upper-alignment;
}
@mixin fs-c- {
  @include xs {
    @include fs-c;
  }
  @include upper-alignment;
}
@mixin sb-fs- {
  @include xs {
    @include sb-fs;
  }
  @include upper-alignment;
}
@mixin st-c- {
  @include xs {
    @include st-c;
  }
  @include upper-alignment;
}
@mixin fe-c- {
  @include xs {
    @include fe-c;
  }
  @include upper-alignment;
}





// @include st-fe;
// @include st-s;
// @include st-e;
// @include st-l;
// @include st-r;
// @include st-sb;
// @include st-sa;
// @include st-se;
// @include fs-fs;
// @include fs-fe;
// @include fs-s;
// @include fs-e;
// @include fs-l;
// @include fs-r;
// @include fs-sb;
// @include fs-sa;
// @include fs-se;
// @include s-fs;
// @include s-fe;
// @include s-s;
// @include s-e;
// @include s-l;
// @include s-r;
// @include s-sb;
// @include s-sa;
// @include s-se;
// @include fe-fs;
// @include fe-fe;
// @include fe-s;
// @include fe-e;
// @include fe-l;
// @include fe-r;
// @include fe-sb;
// @include fe-sa;
// @include fe-se;
// @include e-fs;
// @include e-fe;
// @include e-s;
// @include e-e;
// @include e-l;
// @include e-r;
// @include e-sb;
// @include e-sa;
// @include e-se;
// @include c-fs;
// @include c-fe;
// @include c-s;
// @include c-e;
// @include c-l;
// @include c-r;
// @include c-sb;
// @include c-sa;
// @include c-se;
// @include b-fs;
// @include b-fe;
// @include b-s;
// @include b-e;
// @include b-l;
// @include b-r;
// @include b-sb;
// @include b-sa;
// @include b-se;
// @include c-c;
// @include fs-c;
// @include sb-fs;
// @include st-c;
// @include fe-c;

// &-st-fs {@include st-fs;}
// &-st-fe {@include st-fe;}
// &-st-s {@include st-s;}
// &-st-e {@include st-e;}
// &-st-l {@include st-l;}
// &-st-r {@include st-r;}
// &-st-sb {@include st-sb;}
// &-st-sa {@include st-sa;}
// &-st-se {@include st-se;}
// &-fs-fs {@include fs-fs;}
// &-fs-fe {@include fs-fe;}
// &-fs-s {@include fs-s;}
// &-fs-e {@include fs-e;}
// &-fs-l {@include fs-l;}
// &-fs-r {@include fs-r;}
// &-fs-sb {@include fs-sb;}
// &-fs-sa {@include fs-sa;}
// &-fs-se {@include fs-se;}
// &-s-fs {@include s-fs;}
// &-s-fe {@include s-fe;}
// &-s-s {@include s-s;}
// &-s-e {@include s-e;}
// &-s-l {@include s-l;}
// &-s-r {@include s-r;}
// &-s-sb {@include s-sb;}
// &-s-sa {@include s-sa;}
// &-s-se {@include s-se;}
// &-fe-fs {@include fe-fs;}
// &-fe-fe {@include fe-fe;}
// &-fe-s {@include fe-s;}
// &-fe-e {@include fe-e;}
// &-fe-l {@include fe-l;}
// &-fe-r {@include fe-r;}
// &-fe-sb {@include fe-sb;}
// &-fe-sa {@include fe-sa;}
// &-fe-se {@include fe-se;}
// &-e-fs {@include e-fs;}
// &-e-fe {@include e-fe;}
// &-e-s {@include e-s;}
// &-e-e {@include e-e;}
// &-e-l {@include e-l;}
// &-e-r {@include e-r;}
// &-e-sb {@include e-sb;}
// &-e-sa {@include e-sa;}
// &-e-se {@include e-se;}
// &-c-fs {@include c-fs;}
// &-c-fe {@include c-fe;}
// &-c-s {@include c-s;}
// &-c-e {@include c-e;}
// &-c-l {@include c-l;}
// &-c-r {@include c-r;}
// &-c-sb {@include c-sb;}
// &-c-sa {@include c-sa;}
// &-c-se {@include c-se;}
// &-b-fs {@include b-fs;}
// &-b-fe {@include b-fe;}
// &-b-s {@include b-s;}
// &-b-e {@include b-e;}
// &-b-l {@include b-l;}
// &-b-r {@include b-r;}
// &-b-sb {@include b-sb;}
// &-b-sa {@include b-sa;}
// &-b-se {@include b-se;}
// &-c-c {@include c-c;}
// &-fs-c {@include fs-c;}
// &-sb-fs {@include sb-fs;}
// &-st-c {@include st-c;}
// &-fe-c {@include fe-c;}



@mixin font-acumin-b {
  font-family: $font-acumin;
  font-variation-settings: 'wdth' 50, 'slnt' 0;
}

@mixin font-acumin-sb {
  font-family: $font-acumin;
  font-variation-settings: 'wdth' 50, 'slnt' 0;

}

@mixin font-acumin-r {
  font-family: $font-acumin;
  font-variation-settings: 'wdth' 50, 'slnt' 0;
}

// font heading styles

@mixin h1 {
  font: 600 normal 60px/90% $font-acumin;
  @include font-acumin-sb;
  letter-spacing: .125rem;
  text-transform: uppercase;
  overflow-wrap: break-word;
  width: 100%;
  margin: 0;
  @include f-r-(15px);
  @include sm {
    font-size: 100px;
  }
  span {
    width: 100%;
    @include md{
      width: unset;
    }
  }
  flex-wrap: wrap;
  @include lg {
    font-size: 125px;
  }
}

@mixin h1-caption {
  font: 500 normal 20px/133% $font-1;
  color: $wild-sand;
  margin: 0;
  width: 100%;
  @include lg {
    font-size: 24px;
  }
}

@mixin h2 {
  font: 600 normal 75px/90% $font-acumin;
  @include font-acumin-sb;
  text-transform: uppercase;
  overflow-wrap: break-word;
  color: $tuna;
  margin: 0;
  width: 100%;
  @include lg {
    font-size: 100px;
  }
}
@mixin h2-caption {
  font: 500 normal 18px/150% $font-1;
  color: $tuna;
  margin: 0;
  width: 100%;
  @include lg {
    font-size: 20px;
  }
}

@mixin h3 {
  font: 600 normal 50px/110% $font-acumin;
  @include font-acumin-sb;
  overflow-wrap: break-word;
  color: $tuna;
  width: 100%;
  @include lg {
    font-size: 75px;
  }
}
