@import "../../mixins.scss";

.nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
    .nav-content{
        max-width: 1440px;
        display: flex;
        padding: 20px 20px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        flex-shrink: 0;
        width: 100%;
        box-sizing: border-box;
        height: 60px;
        @include lg {
            padding: 20px 100px;
            justify-content: flex-start;
            height: 120px;
        }
    }
    .nav-logo-img {
        width: 150px;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include lg {
            width: 200px;
        }
    }
}