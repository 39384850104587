@import "../../mixins.scss";

.contact-us-form {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 43px;
  border-radius: 15px;
  background: white;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  @include lg {
    padding: 35px 30px;
  }
  .cuf-title {
    font: normal 700 27px/normal $font-a, $font-c;
    color: black;
    letter-spacing: 0.54px;
    align-self: stretch;
  }
  .cuf-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    align-self: stretch;
  }
}