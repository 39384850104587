@import "../../mixins.scss";

  .articleList {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    padding: 0px 20px;
    box-sizing: border-box;
    gap: 20px;
    @include lg {
        padding: 0px 120px;
        gap: 50px;
    }
    h2{
        color: $cc-25;
        font: normal 700 60px/115% $font-a, $font-c;
        margin: 0px;
        @include lg {
            font-size: 75px;
        }
    }
  }
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
    justify-items: center;
    @include md {
        grid-template-columns: repeat(3, 1fr);
    }
    h3, a {
        color: $wild-sand;
    }
}
.articleContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 360px;
    width: 100%;
    height: 100%;
    .author {
        font: normal 700 10px/15px $font-a, $font-c;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: $wild-sand;
        margin: 0px;
    }
    .articleLink {
      padding: 30px 0px 0px 0px;
      margin: 0px;
      a {
        font: normal 600 16px/24px $font-a, $font-c;
        text-decoration: none;
        letter-spacing: 0.05em;
      }
    }
    .title {
        font: normal 400 18px/125% $font-a, $font-c;
        color: $white;
        margin: 0px;
        @include lg {
            font-size: 21px;
        }
    }
    .desc {
      font: normal 400 14px/150% $font-a, $font-c;
      word-break: break-word;
      margin: 0px;
      letter-spacing: 0.12em;
      color: $white;
      @include lg {
          font-size: 16px;
      }
    }
  
    .imgContainer {
      width: 100%;
      max-height: 216px;
      height: 100%;
      max-height: 197px;
      img {
        width: 100%;
        height: 100%;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        object-fit: cover;
      }
    }
  }
  .articlePagination {
    :global(.MuiStack-root) {
      position: relative;
    }
    li:first-child {
      position: absolute;
      left: 0;
    }
    li:last-child {
      position: absolute;
      right: 0;
    }
  }
  
  .pagination {
    :global(.MuiButtonBase-root-MuiPaginationItem-root) {
      .Mui-selected {
        background-image: url("../../assets/SVGs/bullet.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    :global(.MuiButtonBase-root) {
        background-image: url("../../assets/SVGs/bullet-inactive.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    ul {
        display: flex;
        gap: 5px;
        :first-child {
            button {
            border-radius: none;
            background-color: transparent;
            border: none;
            background-image: none;
            }
        }
        :last-child {
            button {
            background-color: transparent;
            border: none;
            border-radius: none;
            background-image: none;
            }
        }
        button {
            color: transparent;
            border-radius: 0px;
            background: transparent;
            height: 30px;
        }
    }
  }
  .noData {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        font: normal 700 16px/150% $font-a, $font-c;
        color: $white;
    }
  }
  
  .releaseMainDiv {
    width: 100%;
  }
  