@import "../../mixins.scss";

.policy {
  display: flex;
  margin: 0px auto;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  @include lg {
    gap: 100px;
  }
  .policy-title {
    display: flex;
    padding: 80px 20px 20px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/images/chordCash-bg.webp");
    box-sizing: border-box;
    background-size: cover;
    align-self: stretch;
    @include lg {
      padding: 170px 120px 50px 120px;
    }
    h1 {
      color: $white;
      letter-spacing: -1.2px;
      max-width: 1440px;
      font: normal 700 32px/120% $font-a, $font-c;
      text-align: center;
      @include lg {
        font-size: 60px;
      }
    }
  }
  .policy-body {
    max-width: 1440px;
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 800px;
    .policy-text {
      font: normal 400 12px/150% $font-a, $font-c;
      color: $white;
      .pt-bold {
        font-weight: 700;
      }
      .pt-underline {
        font-weight: 500;
        text-decoration: underline;
      }
      .pt-italics {
        font-style: italic;
      }
      @include lg {
        font-size: 20px;
      }
    }
  }
}