@import "../../mixins.scss";

html {
  scroll-behavior: smooth;
}

.back-to-top {
  position: fixed;
  bottom: 0px;
  right: 3%;
  .btt-container {
    @include f-cc;
    height: 35px;
    width: 48px;
    border-radius: 4px 4px 0px 0px;
    background-color: $tuna;
    &:hover {
      background-color: $mineshaft;
      transition: background-color 0.2s linear;
    }
    .btt-text {
      @include f-cc;
      font: normal 15px/15px $font-a, $font-c;
      color: $white;
      .btt-text-icon {
        margin-top: 4px;
        font: normal 15px/15px $font-a, $font-c;
        color: $white;
      }
    }
  }
}